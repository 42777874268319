import React, { useEffect } from 'react';
import Layout from '../../layout/Layout';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

import './styles.css';

const Demo3D = () => {
  useEffect(() => {
    // Set up scene, camera, and renderer
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.querySelector('.demo-content').appendChild(renderer.domElement);

    // Add lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 1.0); // Soft ambient light to illuminate the entire scene
    scene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 2); // Directional light to create shadows
    directionalLight.position.set(5, 5, 5); // Position the light to cast shadows
    directionalLight.castShadow = true;
    scene.add(directionalLight);

    renderer.shadowMap.enabled = true; // Enable shadows in the renderer
// Add X and Y axes (in red and green colors)
    const axesHelper = new THREE.AxesHelper(5); // Length of axes is 5 units
    scene.add(axesHelper);

    // Create a rectangular box with a green color
    const geometry = new THREE.BoxGeometry(3.2, 0.3, 1.8); // Rectangle dimensions: 3.2x1.8x1
    const material = new THREE.MeshStandardMaterial({ color: 0x3ab284 }); // Green color with lighting effects
    const cube = new THREE.Mesh(geometry, material);
    cube.castShadow = true;
    cube.receiveShadow = true;
    scene.add(cube);

    // Create rectangle 1: placed in the center of the bottom box, slightly smaller on all sides
    const rectangle1Geometry = new THREE.BoxGeometry(2.0, 0.1, 1.4); // Slightly smaller dimensions: 2.0x1.4x0.1
    const rectangle1Material = new THREE.MeshStandardMaterial({ color: 0xffff8c }); // Yellow color with lighting effects
    const rectangle1 = new THREE.Mesh(rectangle1Geometry, rectangle1Material);
    rectangle1.position.set(0, 0.2, 0); // Adjusted to ensure rectangle 1 is directly on top of the bottom box
    rectangle1.castShadow = true;
    rectangle1.receiveShadow = true;
    scene.add(rectangle1);

    // Calculate the distance between the edge of rectangle 1 and the bottom box edge
    const distanceToEdge = (3.2 - 2.0) / 2; // Calculate distance from the edge of rectangle 1 to the edge of the bottom box

    // Create rectangle 2: skinny rectangle connecting to rectangle 1 and extending to the edge
    const rectangle2Geometry = new THREE.BoxGeometry(0.1, 0.1, distanceToEdge); // Skinny rectangle with length equal to the distance to the edge
    const rectangle2Material = new THREE.MeshStandardMaterial({ color: 0xffff8c }); // Yellow color with lighting effects
    const rectangle2 = new THREE.Mesh(rectangle2Geometry, rectangle2Material);
    rectangle2.position.set((2.0 / 2) + (distanceToEdge / 2), 0.2, 0); // Adjusted to ensure rectangle 2 is directly connected to rectangle 1
    rectangle2.rotation.y = Math.PI / 2; // Rotate rectangle 2 on the Y (green) axis by 90 degrees
    rectangle2.castShadow = true;
    rectangle2.receiveShadow = true;
    scene.add(rectangle2);

    // Create a new thin rectangle with equal length and width to the bottom box and add it to the bottom
    const bottomRectangleGeometry = new THREE.BoxGeometry(3.2, 0.1, 1.8); // Dimensions equal to the bottom box
    const bottomRectangleMaterial = new THREE.MeshStandardMaterial({ color: 0xffff8c }); // Yellow color with lighting effects
    const bottomRectangle = new THREE.Mesh(bottomRectangleGeometry, bottomRectangleMaterial);
    bottomRectangle.position.set(0, -0.2, 0); // Adjusted to ensure the bottom rectangle is directly below the bottom box
    bottomRectangle.castShadow = true;
    bottomRectangle.receiveShadow = true;
    scene.add(bottomRectangle);

    // Position the camera to a 45-degree angle (away from the top view)
    camera.position.set(3, 3, 3); // Adjusted to give a 45-degree view on X, Y, Z axes
    camera.lookAt(0, 0, 0); // Look at the center of the scene

    // OrbitControls for user interaction
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // Smooth dragging
    controls.dampingFactor = 0.05;

    // Function to create text labels for dimensions
    const createText = (text, position) => {
      const loader = new FontLoader();
      loader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', (font) => {
        const textGeometry = new TextGeometry(text, {
          font: font,
          size: 0.1,
          height: 0.02,
        });
        const textMaterial = new THREE.MeshStandardMaterial({ color: 0x000000 }); // Black text with lighting effects
        const textMesh = new THREE.Mesh(textGeometry, textMaterial);
        textMesh.position.set(position.x, position.y, position.z);
        scene.add(textMesh);
      });
    };

    // Add text labels for the rectangle's dimensions
    createText('3.2 cm', { x: 1.6, y: 0, z: 1.1 }); // Length
    createText('1.8 cm', { x: 0, y: 0.8, z: 1.1 }); // Width
    createText('1 cm', { x: -0.2, y: 0, z: 0.6 }); // Height

    // Animation loop
    const animate = function () {
      requestAnimationFrame(animate);
      controls.update(); // Required if controls.enableDamping = true
      renderer.render(scene, camera);
    };

    animate();

    // Clean up the renderer and scene on component unmount
    return () => {
      renderer.dispose();
      document.querySelector('.demo-content').removeChild(renderer.domElement);
    };
  }, []);

  return (
    <Layout>
      <div className="demo-page">
        <div className="demo-content"></div>
      </div>
    </Layout>
  );
};

export default Demo3D;
