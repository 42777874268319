import ReactGA4 from "react-ga4";

const GA4_MEASUREMENT_ID = process.env.NODE_ENV === 'production' ? 'G-T1N4MR5N2M' : 'G-9999999999';

export const initGA = () => {
    ReactGA4.initialize([
        {
            trackingId: GA4_MEASUREMENT_ID
        }
    ]);
}

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action 
 * @param {string} label 
 */
export const Event = ({ category, action, label }) => {
    ReactGA4.event({
        category,
        action,
        label
    });
};

export const gaCategory = {
    // Categories are feature pages or components
    USER_INTEREST: "User interest",
    EXPLORE_INFO: "Explore info"
}

export const gaAction = {
    BUTTON_CLICK: "Button click",
    NAV_MENU_ITEM_CLICK: "Navbar menu item click"
}

export const gaLabel = {
    MAIN_ACTION_BUTTON: 'Main action button clicked'
}