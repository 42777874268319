import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter, useHistory } from 'react-router-dom';

import { logOutUser } from '../../store/actions/authActions';
import './styles.css';

import { isMobile } from "react-device-detect";
//import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Logo from '../../images/Oroara_logo.png';
import { Event, gaCategory, gaAction, gaLabel } from '../../utils/analytics';

import { AppBar, Toolbar, Button, Drawer, List, ListItem, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import { BlurOn } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import emailjs from '@emailjs/browser';

//const Navbar = ({ auth, logOutUser, history }) => {
const Navbar = () => {

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState('');
  const [openContact, setOpenContact] = useState(false);
  const [contactFormData, setContactFormData] = useState({
    description: '',
    name: '',
    contact: ''
  });
  const [submitContactStatus, setSubmitContactStatus] = useState(null);
  const history = useHistory();

  const navItems = [
    { label: 'Login', link: '/login' }
  ];


  const handleOpenMenu = (event, label) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(label);
    setMenuOpen(true);

    Event({ category: gaCategory.EXPLORE_INFO, action: gaAction.NAV_MENU_ITEM_CLICK, label: `${gaAction.NAV_MENU_ITEM_CLICK}:${label}` });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentMenu('');
    setMenuOpen(false);
  };

  const handleContactActionButtonClick = () => {
    setOpenContact(true);
    Event({ category: gaCategory.USER_INTEREST, action: gaAction.BUTTON_CLICK, label: gaLabel.MAIN_ACTION_BUTTON });
  };

  const handleCloseContact = () => {
    setOpenContact(false);
  };

  const handleContactFormChange = (e) => {
    setContactFormData({
      ...contactFormData,
      [e.target.name]: e.target.value
    });
  };

  const handleContactFormSubmit = () => {
    emailjs.send('service_5a79la3', 'template_qbyuf7n', contactFormData, '1eJ3ws1YtOIGQ30s4')
      .then(() => {
        setSubmitContactStatus('success');
      }, (error) => {
        setSubmitContactStatus('error');
        console.log(error.text);
      });
  };

  const handleNavigation = (link) => {
    history.push(link);
    setDrawerOpen(false);
  };

  return (
    <Container className="navbar-container" maxWidth={isMobile ? 'xs' : 'xl'}>
      <nav className="navbar">
        <Fab className="logo" variant="extended" style={{ textTransform: 'none', zIndex: 100, borderRadius: '1px' }} onClick={() => history.push('/')}>
          <img src={Logo} style={{ height: '30px' }} />
          {/*<div className="logoTxt">Oroara</div>*/}
        </Fab>

        <div className="nav-links flex-1">
          <AppBar position="static" className="nav-item-bar">
            <Toolbar className="nav-item-toolbar">
              {isMobile ? (
                <>
                  <IconButton className="nav-menu-icon-btn" color="inherit" onClick={() => setDrawerOpen(true)}>
                    <MenuIcon className="nav-menu-icon" />
                  </IconButton>
                  <Drawer anchor="top" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton onClick={() => setDrawerOpen(false)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <List>
                      {navItems.map((item, index) => (
                        <React.Fragment key={index}>
                          <ListItem button onClick={() => handleNavigation(item.link)}>
                            {item.icon}
                            <div>{item.label}</div>
                          </ListItem>


                        </React.Fragment>
                      ))}
                    </List>
                  </Drawer>
                </>
              ) : (
                navItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <Button
                      className="nav-item"
                      style={{ textTransform: 'none' }}
                      onClick={() => handleNavigation(item.link)}
                    >
                      {item.icon}
                      <div>{item.label}</div>
                    </Button>

                  </React.Fragment>
                ))
              )}
            </Toolbar>
          </AppBar>
        </div>

        {!isMobile &&
          <Button className="action" variant="extended" style={{ textTransform: 'none', zIndex: 100, borderRadius: '1px' }} onClick={handleContactActionButtonClick}>
            <div>Try Oroara</div>
          </Button>}
      </nav>

      <Dialog open={openContact} onClose={handleCloseContact}>
        <DialogTitle>{submitContactStatus === 'success' ? "Success" : "Interested to try Oroara?"}</DialogTitle>
        <DialogContent>
          {submitContactStatus === 'success' ? (
            <div>Message sent successfully! We will get back soon.</div>
          ) : (
            <>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                label="What's your name?"
                type="text"
                fullWidth
                value={contactFormData.name}
                onChange={handleContactFormChange}
              />
              <TextField
                margin="dense"
                name="description"
                label="Organization"
                type="text"
                fullWidth
                value={contactFormData.description}
                onChange={handleContactFormChange}
              />
              <TextField
                margin="dense"
                name="contact"
                label="What's your email?"
                type="text"
                fullWidth
                value={contactFormData.contact}
                onChange={handleContactFormChange}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {submitContactStatus === 'success' ? (
            <Button onClick={handleCloseContact} color="primary">
              Close
            </Button>
          ) : (
            <>
              <Button onClick={handleCloseContact} color="primary">
                Cancel
              </Button>
              <Button onClick={handleContactFormSubmit} color="primary">
                Send
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

    </Container>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(withRouter, connect(mapStateToProps, { logOutUser }))(Navbar);
