import React from 'react';
import Layout from '../../layout/Layout';
import './styles.css';

const About = () => {
  return (
    <Layout>
      <div className="about-page">
        <div className="about-content">
          <p>
            Automate radio frequency (RF) communication design. Our propriatary AI models can generate and simulate antenna performance. Instead of days and weeks to generate the design and simulation, it now takes minutes, with low learning curve.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default About;
